import damageTypes from '../constants/damageTypes'
import { tasksConfig } from '../constants/tasks'

const lowerCaseFirst = string => {
    return string[0].toLowerCase() + string.substring(1)
}

const textFromCamelCase = camelCaseString => {
    let text = camelCaseString.replace(/([A-Z])/g, ' $1')

    return text.charAt(0).toLocaleUpperCase() + text.slice(1)
}

const typesToNames = () => {
    const typeNames = {}
    damageTypes.forEach(damageType => {
        typeNames[damageType.value] = damageType.label
    })

    return typeNames
}

const searchTimeout = (callback, timer) => {
    clearTimeout(timer)
    if (typeof callback === 'function') {
        timer = setTimeout(callback, 500)
    }

    return timer
}

const getStepNumber = (searchedStep, activeTask) => {
    const task = tasksConfig.find(tC => tC.name === activeTask)
    if (task?.steps) {
        for (const step in task.steps) {
            if (task.steps[step] === searchedStep) {
                return Number(step)
            }
        }
    }

    return 1
}

const getStepNameByNumber = (searchedStepNumber, activeTask) => {
    const task = tasksConfig.find(tC => tC.name === activeTask)
    if (task?.steps) {
        for (const step in task.steps) {
            if (Number(step) === searchedStepNumber) {
                return task.steps[step]
            }
        }
    }

    return null
}

export {
    lowerCaseFirst,
    textFromCamelCase,
    typesToNames,
    searchTimeout,
    getStepNumber,
    getStepNameByNumber
}
