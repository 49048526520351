const tasksConfig = [
    {
        name: 'CustomerCheckin',
        steps: {
            1: 'LicensePlate',
            2: 'Authority',
            3: 'IdentityCard',
            4: 'HandoverProtocol',
            5: 'Mileage',
            6: 'Damages',
            7: 'Summary',
            8: 'LogisticsPartner'
        }
    },
    {
        name: 'CustomerCheckout',
        steps: {
            1: 'LicensePlate',
            2: 'Authority',
            3: 'IdentityCard',
            4: 'DrivingLicense',
            5: 'HandoverProtocol',
            6: 'Mileage',
            7: 'Damages',
            8: 'Summary',
            9: 'LogisticsPartner'
        }
    },
    {
        name: 'DrivingServiceCheckin',
        steps: {
            1: 'Vin',
            2: 'LicensePlate',
            3: 'Driver',
            4: 'IdentityCard',
            5: 'DrivingLicense',
            6: 'Damages',
            7: 'Summary',
            8: 'LogisticsPartner'
        }
    },
    {
        name: 'DrivingServiceCheckout',
        steps: {
            1: 'Vin',
            2: 'LicensePlate',
            3: 'Driver',
            4: 'IdentityCard',
            5: 'DrivingLicense',
            6: 'Damages',
            7: 'Summary',
            8: 'LogisticsPartner'
        }
    },
    {
        name: 'RecordMissingPartsCheckin',
        steps: {
            1: 'LicensePlate',
            2: 'RecordedMissingParts',
            3: 'Summary'
        }
    },

]

const taskTypes = {
    CHECKIN : 'checkin',
    CHECKOUT: 'checkout',
    MISSING_PARTS_CHECKIN: 'missing_parts_checkin'
}

const getTaskConfigByName = taskName => {
    return tasksConfig.find(tC => tC.name === taskName)
}

export { tasksConfig, taskTypes, getTaskConfigByName }
