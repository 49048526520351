import taskMixin from '../../../mixins/taskMixin'
import Navigation from '../../navigation/Navigation.vue'

export default {
    name: 'RecordMissingPartsCheckin',

    mixins: [taskMixin],

    components: {
        Navigation
    },

    data() {
        return {
            taskTitle: 'Fehlteile zurückgeben'
        }
    }
}