import { mapActions, mapGetters } from 'vuex'
import { getStepNumber } from '../../../utils/index'

export default {
    name: 'VoteDialog',

    props: ['task', 'taskTitle'],

    data() {
        return {
            showDialog: true
        }
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        isAlternativePerson() {
            return (
                this.form &&
                this.form.authority &&
                this.form.authority.alternativePerson
            )
        }
    },

    methods: {
        ...mapActions({
            setLastFinishedStep: 'form/setLastFinishedStep'
        }),

        voteAndNext(vote) {
            this.$store.commit('form/setVote', vote)
            this.next()
        },

        next() {
            const stepNumber = getStepNumber('Summary', this.task)
            const nextStepNumber = getStepNumber('LogisticsPartner', this.task)

            this.setLastFinishedStep(stepNumber)
            this.$router.push({
                name: this.task,
                params: { step: nextStepNumber }
            })
        }
    }
}
