import EventBus from '../../utils/eventBus'
import FormInput from '../form/formInput/FormInput.vue'
import FormSelect from '../form/formSelect/FormSelect.vue'
import { mapActions } from 'vuex'

export default {
    name: 'Login',

    props: {
        message: {
            type: String,
            required: false,
            default: null
        }
    },

    components: {
        FormInput,
        FormSelect
    },

    data() {
        return {
            email: null,
            password: null,
            errors: [],
            outputError: false,
            isLoading: false,
        }
    },

    created() {
        this.checkLogin()

        // If the app shows the login screen called from other component,
        // it will show a message to inform the user.
        if (this.message) {
            EventBus.$emit('showDialog', {
                dialog: {
                    name: 'error',
                    type: 'hint',
                    errors: [this.message]
                }
            })
        }
    },

    computed: {
        loginButtonDisabled() {
            return !this.email || !this.password
        }
    },

    methods: {
        ...mapActions({
            fetchToken: 'user/fetchToken',
            fetchRefreshedToken: 'user/fetchRefreshedToken',
            fetchUserForToken: 'user/fetchUserForToken'
        }),

        getToken() {
            return localStorage.getItem('userToken') || null
        },

        async checkLogin() {
            let newToken, user

            // Get token from local storage
            const userToken = this.getToken()
            if (userToken) {
                this.isLoading = true

                // Refresh token
                newToken = await this.fetchRefreshedToken(userToken)

                // Check for errors
                if (newToken && newToken.error) {
                    // Clear token
                    this.$store.commit('user/setLoggedInUser', {})

                    newToken = null
                }

                if (newToken) {
                    user = await this.fetchUserForToken(newToken)

                    if (user && user.error) {
                        // Clear token
                        this.$store.commit('user/setLoggedInUser', {})

                        user = null
                    }
                }

                // if we get a user with token, redirect
                if (user && user.token) {
                    const redirectAction = localStorage.getItem('redirectAction')
                    let redirectRoute = 'Dashboard'
                    if (['checkin', 'checkout'].includes(redirectAction)) {
                        redirectRoute = 'Customer' + redirectAction.charAt(0).toUpperCase() + redirectAction.slice(1);
                    }
                    this.$router.push({ name: redirectRoute })
                }

                this.isLoading = false
            }
        },

        validate(withOutput = false) {
            let isValid = true
            this.outputError = false
            this.errors = []

            if (!this.email) {
                isValid = false
                this.errors.push({
                    field: 'email',
                    message: 'Bitte geben Sie Ihre E-Mail-Adresse an.'
                })
            }

            if (!this.password) {
                isValid = false
                this.errors.push({
                    field: 'password',
                    message: 'Bitte geben Sie Ihr Password an.'
                })
            }

            if (withOutput && this.errors.length > 0) {
                this.outputError = true

                const errorMessages = []
                this.errors.forEach(error => {
                    errorMessages.push(error.message)
                })

                EventBus.$emit('showDialog', {
                    dialog: {
                        name: 'error',
                        type: 'hint',
                        errors: errorMessages
                    }
                })
            }

            return isValid
        },

        authError() {
            this.outputError = false
            this.errors = []
            this.outputError = true

            EventBus.$emit('showDialog', {
                dialog: {
                    name: 'error',
                    type: 'hint',
                    errors: [
                        'Ihre E-Mail/Passwort-Kombination stimmt nicht, bitte überprüfen Sie Ihre Eingabe.'
                    ]
                }
            })
        },

        networkError() {
            this.outputError = false
            this.errors = []

            EventBus.$emit('showDialog', {
                dialog: {
                    name: 'error',
                    type: 'hint',
                    errors: [
                        'Es gab ein Problem bei Ihrer Anmeldung. Bitte versuchen Sie es erneut oder kontaktieren Sie Fleetpool.'
                    ]
                }
            })
        },

        async login() {
            let user

            if (this.validate(true)) {
                this.isLoading = true

                user = await this.fetchToken({
                    username: this.email,
                    password: this.password
                })

                this.isLoading = false

                if (user && user.error) {
                    const statusCode = user.error

                    if (statusCode === 403) {
                        this.authError()
                    } else {
                        this.networkError()
                    }
                } else if (user && user.token) {
                    this.$router.push({ name: 'Dashboard' })
                } else {
                    this.authError()
                }
            }
        }
    },

    watch: {
        email() {
            this.validate()
        },

        password() {
            this.validate()
        }
    }
}
