'use strict'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

function getRelease() {
    return process.env.VUE_APP_COMMIT
}

function getEnvironment() {
    return process.env.VUE_APP_TENANT || (process.env.NODE_ENV === 'production' ? 'production' : 'unknown')
}

function getTracesSampleRate() {
    let sampleRate = process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE
        ? Number(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE)
        : 0.1
    if (!isNaN(sampleRate)) {
        sampleRate = 0.1
    } else if (sampleRate > 1) {
        sampleRate = 1
    } else if (sampleRate < 0) {
        sampleRate = 0
    }

    return sampleRate
}

function logErrors() {
    return (process.env.VUE_APP_TENANT || '') !== ''
}

function skipUserDialog(event) {
    if (
        event &&
        event.exception &&
        event.exception.mechanism &&
        event.exception.mechanism.type === 'onunhandledrejection'
    ) {
        return true
    }

    return false
}

function beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && process.env.VUE_APP_SENTRY_USER_FEEDBACK && !skipUserDialog(event)) {
        Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
                name: 'user',
                email: 'user-report@twyte.dev'
            },
            onLoad() {
                const elements = document.querySelectorAll('.sentry-error-embed-wrapper .form-content .form-field:not(:nth-child(3))')
                elements.forEach(el => el.style.display = 'none')
            }
        })
    }

    return event
}

export function initSentry(Vue, router) {
    if (!process.env.VUE_APP_SENTRY_DSN) {
        return
    }

    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [/^\//],
            }),
        ],
        logErrors: logErrors(),
        environment: getEnvironment(),
        release: getRelease(),
        tracesSampleRate: getTracesSampleRate(),
        tunnel: '/api/sentry.php',
        beforeSend,
    })
}
