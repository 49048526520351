import taskMixin from '../../../mixins/taskMixin'
import Navigation from '../../navigation/Navigation.vue'

export default {
    name: 'CustomerCheckout',

    mixins: [taskMixin],

    components: {
        Navigation
    },

    data() {
        return {
            taskTitle: 'Kunde Checkout'
        }
    }
}
