const defaultForm = {
    // General data
    process_id: null,
    vehicle: {},
    vehicleByVin: {},
    vehicleByLicensePlate: {},
    task: null,

    // Steps
    vin: {},
    licensePlate: {},
    authority: {},
    driver: {},
    identityCard: {},
    drivingLicense: {},
    recordedMissingParts: [],
    handoverProtocol: {
        additionalItems: '',
        dividingNet: false,
        electricCable: false,
        firstAidKit: false,
        footMatsFront: false,
        footMatsRear: false,
        tirefit: false,
        repairKit: false,
        sparetire: false,
        warningSign: false,
        warningWest: false,
        tireRimCovers: false,
        heatingRemoteControl: false,
        cableSchuko: false,
        cableTyp2: false,
        cableCCS: false,
        keys: null,
        logBook: false,
        luggageCompartmentCover: false,
        missingItems: [],
        missingItemsText: '',
        missingItemsBringCustomer: false,
        missingItemsBringUntil: null,
        customerGoodIntentions: {},
        navigationSdCard: false,
        navigationSystem: false,
        tires: null,
        fuelLevel: null,
        trailerHitch: false,
        vehicleRegistration: false,
    },
    mileage: {},
    damages: {
        selectedDamages: []
    },
    summary: {
        correctPin: false,
        confirmRetunredMissingItems: false
    },
    logisticsPartner: {},
    isExchange: false,
    lastFinishedStep: null,
    log: [],
    runningSearchCount: 0,
    finishedPreviousTaskForCustomer: false,
    vote: null,
    login: {
        language: 'de'
    }
}

const defaultOcr = {
    vin: {
        loading: false,
        error: null
    },
    licensePlate: {
        loading: false,
        error: null
    }
}

export { defaultForm, defaultOcr }
