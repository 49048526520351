import EventBus from '../../../utils/eventBus'
import FormTextarea from '../../form/formTextarea/FormTextarea.vue'
import { dispoTeamPhoneNumber } from '../../../constants/general'
import { mapActions } from 'vuex'

export default {
    name: 'ConfirmCancelDialog',

    components: {
        FormTextarea
    },

    props: ['task', 'taskTitle'],

    data() {
        return {
            showDialog: true,
            dispoTeamPhoneNumber
        }
    },

    mounted() {
        // Fix for v-file-input not triggering properly
        // https://github.com/vuetifyjs/vuetify/issues/10832
        const inputs = document.querySelectorAll('.v-file-input input')
        ;[...inputs].forEach(input => {
            input.remove()
        })
    },

    methods: {
        ...mapActions({
            reset: 'form/reset'
        }),

        hide() {
            EventBus.$emit('hideDialog')
        },

        cancel() {
            EventBus.$emit('cancel', {
                task: this.task
            })

            this.hide()
            this.reset()

            const sdm = localStorage.getItem('userMode') == 'sdm'
            if (sdm) {
                this.$router.push({ name: 'Logout' })
                window.close()
            } else {
                this.$router.push({ name: 'Dashboard' })
            }
        }
    }
}
