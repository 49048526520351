import Vue from 'vue'
import EventBus from '../utils/eventBus'
import { mapGetters, mapActions } from 'vuex'
import { lowerCaseFirst, getStepNumber } from '../utils/index'
import { getTaskConfigByName } from '../constants/tasks'
import forceLoginMixin from './forceLoginMixin'

export default {
    mixins: [forceLoginMixin],

    data() {
        return {
            task: this.$options.name,
            component: null,
            stepIsValid: false
        }
    },

    created() {
        this.loadComponentsForStep()
        this.checkForValidStep()
        this.setTask(this.task)
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        activeStep() {
            return Number(this.$route?.params?.step) || 1
        },

        summaryField() {
            return this.form.summary || {}
        },

        isSummary() {
            const summaryStepNumber = Number(
                getStepNumber('Summary', this.task)
            )
            return this.activeStep === summaryStepNumber
        }
    },

    methods: {
        ...mapActions({
            log: 'form/log',
            setTask: 'form/setTask'
        }),

        loadComponentsForStep() {
            const component = this.getComponent()
            let componentName = null

            if (component) {
                // Load component
                componentName = lowerCaseFirst(component)
                Vue.component(component, () =>
                    import(
                        `../components/steps/${componentName}/${component}.vue`
                    )
                )

                this.component = component
                this.log({
                    step: this.component,
                    details: { type: 'view' }
                })
            } else {
                // Redirect to dashboard
                this.$router.push({ name: 'Dashboard' })
            }
        },

        getComponent() {
            let component = null

            const taskConfig = getTaskConfigByName(this.task)
            if (taskConfig) {
                component = taskConfig?.steps[this.activeStep]
            }

            return component
        },

        checkForValidStep() {
            let redirectTo = 1

            if (this.form.lastFinishedStep) {
                redirectTo = this.form.lastFinishedStep
            }

            if (this.form.lastFinishedStep < this.activeStep - 1) {
                this.$router.push({
                    name: this.task,
                    params: { step: redirectTo }
                })
            }
        },

        submitForm() {
            EventBus.$emit('send', {
                task: this.task,
                title: this.taskTitle
            })
        }
    },

    watch: {
        activeStep() {
            this.loadComponentsForStep()
        }
    }
}
