import Vue from 'vue'
import _ from 'lodash'
import AppointmentsService from '../../services/AppointmentsService'

export default {
    namespaced: true,
    state: {
        appointments: []
    },
    mutations: {},
    actions: {
        fetchAppointments({ state }, payload) {
            return new Promise((resolve, reject) => {
                AppointmentsService.fetchAppointments(payload)
                    .then(response => {
                        if (response.status === 200) {
                            let data =
                                (response.data && response.data.data) || []

                            // Sort by startDate
                            data = _.orderBy(data, ['startDate'], ['asc'])
                            Vue.set(state, 'appointments', data)
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(e => {
                        const statusCode = e.response && e.response.status
                        reject(statusCode)
                    })
            })
        }
    },
    getters: {
        appointments: state => state.appointments
    }
}
