import Dashboard from '../components/dashboard/Dashboard.vue'
import Login from '../components/login/Login.vue'
import Logout from '../components/logout/Logout.vue'
import SdmError from '../components/sdmError/SdmError.vue'
import CustomerCheckin from '../components/tasks/customerCheckin/CustomerCheckin.vue'
import CustomerCheckout from '../components/tasks/customerCheckout/CustomerCheckout.vue'
import DrivingServiceCheckin from '../components/tasks/drivingServiceCheckin/DrivingServiceCheckin.vue'
import DrivingServiceCheckout from '../components/tasks/drivingServiceCheckout/DrivingServiceCheckout.vue'
import RecordMissingPartsCheckin from '../components/tasks/recordMissingPartsCheckin/RecordMissingPartsCheckin.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: true
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        props: true
    },
    {
        path: '/customer-checkin/:step?',
        name: 'CustomerCheckin',
        component: CustomerCheckin
    },
    {
        path: '/customer-checkout/:step?',
        name: 'CustomerCheckout',
        component: CustomerCheckout
    },
    {
        path: '/driving-service-checkin/:step?',
        name: 'DrivingServiceCheckin',
        component: DrivingServiceCheckin
    },
    {
        path: '/driving-service-checkout/:step?',
        name: 'DrivingServiceCheckout',
        component: DrivingServiceCheckout
    },
    {
        path: '/record-missing-parts/:step?',
        name: 'RecordMissingPartsCheckin',
        component: RecordMissingPartsCheckin
    },
    {
        path: '/sdm-error',
        name: 'SDM Error',
        component: SdmError
    }
]

export { routes }
