export default {
    data() {
        return {}
    },

    computed: {
        taskName() {
            return this.task || (this.$options?.name)
        },

        isDrivingServiceTask() {
            return ['DrivingServiceCheckin' , 'DrivingServiceCheckout'].includes(this.taskName)
        },

        isCustomerTask() {
            return ['CustomerCheckin', 'CustomerCheckout'].includes(this.taskName)
        },

        isCheckout() {
            return ['DrivingServiceCheckout', 'CustomerCheckout'].includes(this.taskName)
        },

        isCheckin() {
            return ['DrivingServiceCheckin', 'CustomerCheckin'].includes(this.taskName)
        },

        isRecordMissingPartsTask() {
            return ['RecordMissingPartsCheckin'].includes(this.taskName)
        },

        summaryFinishedByCustomer() {
            let summaryFinishedByCustomer = false

            // On Checkin we do not use PIN check
            if (
                this.isCheckin &&
                this.form?.summary?.confirmed &&
                this.form.summary?.confirmedMissingItemsCustomer &&
                this.form.summary?.signature
            ) {
                summaryFinishedByCustomer = true
            }

            if (
                this.isCheckout &&
                this.form?.summary?.confirmed &&
                this.form.summary?.signature &&
                this.form.summary?.correctPin
            ) {
                summaryFinishedByCustomer = true
            }

            return summaryFinishedByCustomer
        }
    }
}
