import EventBus from '../../../utils/eventBus'
import { mapActions, mapGetters } from 'vuex'
import checkTaskMixin from '../../../mixins/checkTaskMixin'

export default {
    name: 'SuccessDialog',

    mixins: [checkTaskMixin],

    props: ['task', 'taskTitle'],

    data() {
        return {
            showDialog: true
        }
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        })
    },

    methods: {
        ...mapActions({
            resetExceptUploads: 'form/resetExceptUploads',
            reset: 'form/reset'
        }),

        checkoutForThisCustomer() {
            this.hide()
            this.resetExceptUploads()
            this.$router.push({ name: 'CustomerCheckout' })
        },

        checkinForThisCustomer() {
            this.hide()
            this.resetExceptUploads()
            this.$router.push({ name: 'CustomerCheckin' })
        },

        hide() {
            EventBus.$emit('hideDialog')
        },

        dashboard() {
            this.hide()
            this.reset()
            const sdm = localStorage.getItem('userMode') == 'sdm'
            if (sdm) {
                this.$router.push({ name: 'Logout' })
            } else {
                this.$router.push({ name: 'Dashboard' })
            }
        }
    }
}
