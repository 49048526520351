import apiClient from './ApiClient'

export default {
    send(payload) {
        return apiClient.post('/api/rpc/checkincheckout/process-completed',
            {
                ...payload.form
            },
            {
                headers: {
                    Authorization: 'API-X-TOKEN ' + payload.token
                }
            }
        )
    },

    returnMissingItems(payload) {
        return apiClient.post('/api/rpc/checkincheckout/missing-items-returned',
            {
                ...payload.form.vehicle.processed.missingItems
            },
            {
                headers: {
                    Authorization: 'API-X-TOKEN ' + payload.token
                }
            }
        )
    },

    cancel(payload) {
        return apiClient.post('/api/rpc/checkincheckout/process-canceled',
            {
                ...payload.form
            },
            {
                headers: {
                    Authorization: 'API-X-TOKEN ' + payload.token
                }
            }
        )
    }
}
