import EventBus from '../../../utils/eventBus'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import PreviewImage from '../../previewImage/PreviewImage.vue'

export default {
    name: 'Photo',

    mixins: [checkTaskMixin],

    props: ['file'],

    components: {
        PreviewImage
    },

    data() {
        return {
            showDialog: true
        }
    },

    methods: {
        hide() {
            EventBus.$emit('hideDialog')
        }
    }
}
