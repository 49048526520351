import { setUser } from '@sentry/vue'
import LoginService from '../../services/LoginService'

const defaultLoggedInUser = {
    firstname: null,
    lastname: null,
    location: null,
    token: null
}

const defaultSessionTimeout = 300

export default {
    namespaced: true,
    state: {
        loggedInUser: JSON.parse(JSON.stringify(defaultLoggedInUser)),
        lastRefresh: null,
        refreshing: false
    },
    mutations: {
        updateLastRefresh(state) {
            state.lastRefresh = +(new Date())
            localStorage.setItem('lastRefresh', state.lastRefresh)
        },
        setRefreshing(state, refreshing) {
            state.refreshing = refreshing
        },
        setLoggedInUser(state, payload) {
            if (!payload) {
                payload = {}
            }
            state.loggedInUser = {
                firstname: payload.firstname,
                lastname: payload.lastname,
                location: payload.location,
                token: payload.token
            }

            setUser({
                id: payload.id,
                username: payload.location
            })

            if (!payload.token) {
                localStorage.removeItem('userToken')
            } else {
                // Save token to local storage
                localStorage.setItem('userToken', payload.token)
                this.commit('user/updateLastRefresh')
            }
        }
    },
    actions: {
        async sessionRefresh({ commit, state, getters, dispatch }) {
            if (state.refreshing) {
                return false
            }

            commit('setRefreshing', true)
            let newToken, user
            let refreshed = false

            // Get token from local storage
            const userToken = state.loggedInUser?.token
            const now = +new Date()
            const lastRefresh = getters.lastRefresh
            const diff = (now - Number(lastRefresh))
            const refreshTimeout = getters.refreshTimeout
            const needsRefresh = !lastRefresh || diff > refreshTimeout

            if (userToken && needsRefresh) {
                // Refresh token
                newToken = await dispatch('fetchRefreshedToken', userToken)
                // Check for errors
                if (newToken && newToken.error) {
                    // Clear token
                    commit('setLoggedInUser', {})
                    newToken = null
                }
                if (newToken) {
                    user = await dispatch('fetchUserForToken', newToken)
                    if (user && user.error) {
                        // Clear token
                        commit('setLoggedInUser', {})
                        user = null
                    }
                }
                if (user && user.token) {
                    refreshed = true
                }
            }

            commit('setRefreshing', false)

            return refreshed
        },
        async fetchToken({ commit }, payload) {
            let response

            try {
                response = await LoginService.fetchToken(payload)
            } catch (e) {
                const statusCode = e.response && e.response.status
                commit('form/log', {
                    step: 'fetchToken',
                    details: { type: 'error', message: e.message }
                },
                { root: true })

                return {
                    error: statusCode
                }
            }

            let user = {}

            if (
                response.status === 200 &&
                response.data &&
                response.data.token
            ) {
                user = response.data
                commit('setLoggedInUser', user)
            }

            return user
        },
        async fetchRefreshedToken(context, token) {
            let response

            try {
                response = await LoginService.fetchRefreshedToken(token)
            } catch (e) {
                const statusCode = e.response && e.response.status

                return {
                    error: statusCode
                }
            }

            return response && response.data && response.data.token
        },
        async fetchUserForToken(context, token) {
            let response

            try {
                response = await LoginService.fetchUserForToken(token)
            } catch (e) {
                return {
                    error: true
                }
            }

            let user = {}

            if (response.status === 200 && response.data && token) {
                user = {
                    firstname: response.data.firstName,
                    lastname: response.data.lastName,
                    location:
                        response.data.location && response.data.location.name,
                    token
                }
                context.commit('setLoggedInUser', user)
            }

            return user
        },
        async logoutUser(context, token) {
            try {
                await LoginService.logout(token)
            } finally {
                context.commit('form/reset', null, { root: true })
                context.commit('setLoggedInUser', defaultLoggedInUser)
            }
        }
    },
    getters: {
        loggedInUser: state => state.loggedInUser,
        lastRefresh: state => state.lastRefresh || localStorage.getItem('lastRefresh') || null,
        refreshTimeout() {
            let timeout = process.env.VUE_APP_SESSION_REFRESH || defaultSessionTimeout
            if (!timeout) {
                timeout = defaultSessionTimeout
            }
            timeout = Number(timeout)
            if (isNaN(timeout)) {
                timeout = defaultSessionTimeout
            }

            return timeout * 1000;
        }
    }
}
