import EventBus from '../../utils/eventBus'

export default {
    name: 'PreviewImage',

    components: {},

    props: [
        'file',
        'mode',
        'toDeleteFct',
        'toDeleteIndex',
        'error',
        'scanning'
    ],

    data() {
        return {
            src: null
        }
    },

    mounted() {
        this.getPhotoSrcFromFile()
    },

    methods: {
        getPhotoSrcFromFile() {
            const photo = this.file || null

            if (photo) {
                const reader = new FileReader()
                const that = this
                reader.onload = function(e) {
                    that.src = e.target.result
                }

                reader.readAsDataURL(photo)
            }
        },

        showPhoto(file) {
            // Do not show dialog if we are already in this view
            if (this.mode === 'dialog') {
                return
            }

            EventBus.$emit('showDialog', {
                dialog: {
                    name: 'photo',
                    title: this.taskTitle,
                    file
                },
                task: this.task
            })
        },

        deletePhoto() {
            this.toDeleteFct(this.toDeleteIndex)
        }
    },

    watch: {
        file() {
            this.getPhotoSrcFromFile()
        }
    }
}
