import EventBus from '../../../../utils/eventBus'
import { dispoTeamPhoneNumber } from '../../../../constants/general'

export default {
    name: 'ErrorOutput',

    props: {
        errors: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            default: 'hint'
        },
        showContact: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dispoTeamPhoneNumber
        }
    },

    methods: {
        hide() {
            EventBus.$emit('hideDialog')
        }
    }
}
