import { mapActions, mapGetters } from 'vuex'
import { getTaskConfigByName } from '../../constants/tasks'
import { getStepNumber, getStepNameByNumber } from '../../utils/index'
import EventBus from '../../utils/eventBus'
import checkTaskMixin from '../../mixins/checkTaskMixin'

export default {
    name: 'Navigation',

    components: {},

    mixins: [checkTaskMixin],

    props: {
        stepIsValid: {
            type: Boolean,
            default: false
        },
        task: {
            type: String
        }
    },

    data() {
        return {
            showSubmit: false,
        }
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        stepComponentName() {
            return getStepNameByNumber(this.activeStep, this.task)
        },

        activeStep() {
            return Number(this.$route?.params?.step) || 1
        },

        lastFinishedStep() {
            return this.form.lastFinishedStep
        },

        maySeeSummary() {
            return (
                this.lastFinishedStep === this.maxSteps - 1 &&
                this.activeStep !== this.maxSteps
            )
        },

        maxSteps() {
            let maxSteps = null

            const taskConfig = getTaskConfigByName(this.task)
            if (taskConfig) {
                maxSteps = Number(Object.keys(taskConfig.steps).length)
            }

            return maxSteps
        },

        summaryField() {
            return this.form.summary || {}
        }
    },

    methods: {
        ...mapActions({
            setLastFinishedStep: 'form/setLastFinishedStep',
            log: 'form/log'
        }),

        summary() {
            const stepNumber = getStepNumber('Summary', this.task)

            this.$router.push({ name: this.task, params: { step: stepNumber } })
        },

        nextStep() {
            let step = this.activeStep + 1

            const activeStepName = getStepNameByNumber(this.activeStep, this.task)

            // Skip step IdentityCard on customer checkin if alternative person is set
            if (
                this.task === 'CustomerCheckin' &&
                activeStepName === 'Authority' &&
                this.form.authority.alternativePerson
            ) {
                // jump to step 'HandoverProtocol'
                step = getStepNumber('HandoverProtocol', this.task)
            }

            this.setLastFinishedStep(this.activeStep)
            this.log({
                step: this.stepComponentName,
                details: { type: 'nextStep' }
            })

            this.$router.push({ name: this.task, params: { step } })
        },

        previousStep() {
            let step = this.activeStep - 1

            const activeStepName = getStepNameByNumber(this.activeStep, this.task)

            // Skip step IdentityCard on customer checkin if alternative person is set
            if (
                this.task === 'CustomerCheckin' &&
                activeStepName === 'HandoverProtocol' &&
                this.form.authority.alternativePerson
            ) {
                // jump to step 'Authority'
                step = getStepNumber('Authority', this.task)
            }

            this.$router.push({ name: this.task, params: { step } })
        },

        cancel() {
            EventBus.$emit('showDialog', {
                dialog: {
                    name: 'cancel',
                    title: this.taskTitle
                },
                task: this.task
            })
        }
    },
    watch: {
        // This is needed to prevent early auto submit of form, wtf
        activeStep: function () {
            let newShowSubmit = this.activeStep === this.maxSteps
            if (!newShowSubmit) {
                this.showSubmit = false
                return
            }

            this.$nextTick(() => {
                this.showSubmit = true
            })
        }
    }
}
