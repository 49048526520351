import Vue from 'vue'
import { parseXml } from '../../constants/helpers'
import ScanService from '../../services/ScanService'
import { defaultOcr } from '../../constants/storeDefaults'

export default {
    namespaced: true,
    state: {
        ocr: JSON.parse(JSON.stringify(defaultOcr))
    },
    mutations: {
        async scanVin(state, payload) {
            const rootState = payload.rootState
            const image = payload.image
            let response

            Vue.set(state.ocr.vin, 'loading', true)
            Vue.set(state.ocr.vin, 'error', null)

            try {
                response = await ScanService.scanVin(image)
            } catch {
                Vue.set(
                    state.ocr.vin,
                    'error',
                    'Die Antwort vom Dienst zum Scannen der FIN war fehlerhaft. Bitte versuchen Sie es erneut.'
                )
            } finally {
                Vue.set(state.ocr.vin, 'loading', false)
            }

            if (response && response.status === 200) {
                const vinNode = parseXml(response.data, 'VIN_Captured')

                let vin = null
                if (vinNode) {
                    vin = vinNode.innerHTML

                    const pattern = /[a-zA-Z0-9]{9}[a-zA-Z0-9-]{2}[0-9]{6}/g
                    if (pattern.test(vin)) {
                        Vue.set(rootState.form.form.vin, 'vin', vin)
                    } else {
                        Vue.set(
                            state.ocr.vin,
                            'error',
                            `Es wurde folgende FIN erkannt: ${vin}. Allerdings ist diese nicht valide. Bitte versuchen Sie es erneut.`
                        )
                    }
                } else {
                    Vue.set(
                        state.ocr.vin,
                        'error',
                        'Es wurde keine FIN erkannt. Bitte versuchen Sie es erneut.'
                    )
                }
            }
        },

        async scanLicensePlate(state, payload) {
            const rootState = payload.rootState
            let response

            Vue.set(state.ocr.licensePlate, 'loading', true)
            Vue.set(state.ocr.licensePlate, 'error', null)
            try {
                response = await ScanService.scanLicensePlate(payload)
            } catch {
                Vue.set(
                    state.ocr.licensePlate,
                    'error',
                    'Die Antwort vom Dienst zum Scannen des Kennzeichens war fehlerhaft. Bitte versuchen Sie es erneut.'
                )
            } finally {
                Vue.set(state.ocr.licensePlate, 'loading', false)
            }

            if (response && response.status === 200) {
                const licencePlate = response.data.licencePlate || ''
                if (licencePlate !== '') {
                    Vue.set(
                        rootState.form.form.licensePlate,
                        'licensePlate',
                        licencePlate
                    )
                }
            } else {
                let errormsg = ''
                if (response && response.data && response.data.error) {
                    errormsg = ': "'+response.data.error+'"'
                }
                Vue.set(
                  state.ocr.licensePlate,
                  'error',
                  `Es wurde kein Kennzeichen erkannt${errormsg}. Bitte versuchen Sie es erneut.`
                )
            }
        },

        resetScanError(state, type) {
            Vue.set(state.ocr[type], 'error', null)
        }
    },
    actions: {
        scanVin(context, image) {
            const payload = {
                rootState: context.rootState,
                image
            }

            context.commit('scanVin', payload)
        },

        scanLicensePlate(context, payload) {
            payload.rootState = context.rootState

            context.commit('scanLicensePlate', payload)
        },

        resetScanError(context, type) {
            context.commit('resetScanError', type)
        }
    },
    getters: {
        ocr: state => state.ocr
    }
}
