import axios from 'axios'

const apiBaseUrl = process.env.VUE_APP_API_BASE ? process.env.VUE_APP_API_BASE : `${location.protocol}//${location.host}`

const apiClient = axios.create({
    baseURL: apiBaseUrl,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default apiClient
