const companies = [
    {
        text: 'Fleetpool',
        value: 'Fleetpool'
    },
    {
        text: 'Carmago',
        value: 'Carmago'
    },
    {
        text: 'KL-Logistik',
        value: 'KL-Logistik'
    },
    {
        text: 'SAT-Dekaj',
        value: 'SAT-Dekaj'
    },
    {
        text: 'Transfercar',
        value: 'Transfercar'
    },
    {
        text: 'Caris',
        value: 'Caris'
    },
    {
        text: 'Suloja',
        value: 'Suloja'
    }
]

const dispoTeamPhoneNumber = '+49 (0)221 29267 699'
const pinSupportPhoneNumber = '+49 (0)221 29267 699'

const maxMileageInput = 999999.99;
const bringMissingPartsPeriod = 3 * 24 * 60 * 60 * 1000; // days * hours * minutes * seconds * milliseconds

export {
    companies,
    dispoTeamPhoneNumber,
    pinSupportPhoneNumber,
    maxMileageInput,
    bringMissingPartsPeriod
}
