import apiClient from './ApiClient'

export default {
    requestPin(payload) {
        return apiClient.post('/api/rpc/checkincheckout/pin-request',
            {
                process_id: payload.processId,
                is_authorized: payload.isAuthorized
            },
            {
                headers: {
                    Authorization: 'API-X-TOKEN ' + payload.token
                }
            }
        )
    },

    validatePin(payload) {
        return apiClient.post('/api/rpc/checkincheckout/pin-check',
            {
                process_id: payload.processId,
                pin: payload.pin
            },
            {
                headers: {
                    Authorization: 'API-X-TOKEN ' + payload.token
                }
            }
        )
    }
}
