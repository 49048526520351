import EventBus from '../../../utils/eventBus'
import { dispoTeamPhoneNumber } from '../../../constants/general'
import { mapActions } from 'vuex'
import checkTaskMixin from '../../../mixins/checkTaskMixin'


export default {
    name: 'CancelDialog',

    components: {},

    props: ['task', 'taskTitle'],

    mixins: [checkTaskMixin],

    data() {
        return {
            showDialog: true,
            dispoTeamPhoneNumber
        }
    },

    methods: {
        ...mapActions({
            reset: 'form/reset'
        }),

        hide() {
            EventBus.$emit('hideDialog')
        },

        cancel() {
            if (this.isRecordMissingPartsTask) {
                this.cancelWithoutReason()
            } else {
                EventBus.$emit('showDialog', {
                    dialog: {
                        name: 'confirmCancel',
                        title: this.taskTitle
                    },
                    task: this.task
                })
            }
        },

        cancelWithoutReason() {
            EventBus.$emit('cancel', {
                task: this.task
            })

            this.hide()
            this.reset()

            const sdm = localStorage.getItem('userMode') == 'sdm'
            if (sdm) {
                this.$router.push({ name: 'Logout' })
                window.close()
            } else {
                this.$router.push({ name: 'Dashboard' })
            }
        }
    }
}
