import Appointments from './appointments/Appointments.vue'
import forceLoginMixin from '../../mixins/forceLoginMixin'

export default {
    name: 'Dashboard',

    mixins: [forceLoginMixin],

    components: {
        Appointments
    }
}
