export default {
    name: 'SdmError',

    mixins: [],

    components: {},

    methods: {
        closeWindow() {
            window.close();
        }
    }
}
