const damageTypes = [
    {
        label: 'Beschädigung',
        value: 'damage'
    },
    {
        label: 'Delle',
        value: 'dent'
    },
    {
        label: 'Kratzer',
        value: 'scratch'
    },
    {
        label: 'Steinschlag / Rissbildung',
        value: 'rockfallOrCrackFormation'
    }
]

export default damageTypes
