import { mapGetters } from 'vuex'
import Appointment from './appointment/Appointment.vue'

export default {
    name: 'Appointments',

    components: {
        Appointment
    },

    data() {
        return {
            isLoading: false
        }
    },

    created() {
        this.fetchAppointments()
    },

    computed: {
        ...mapGetters({
            appointments: 'appointments/appointments',
            loggedInUser: 'user/loggedInUser'
        })
    },

    methods: {
        scrollToNextAppointment() {
            const listNode = document.querySelector('div.appointments ul.list')
            if (listNode) {
                const nextAppointmentNode = listNode.querySelector(
                    'li:not(.timesUp)'
                )
                if (nextAppointmentNode) {
                    listNode.scrollTo(0, nextAppointmentNode.offsetTop)
                }
            }
        },
        async fetchAppointments(refresh = false) {
            if (this.loggedInUser && this.loggedInUser.token) {
                this.isLoading = true

                const payload = {
                    token: this.loggedInUser.token,
                    refresh
                }

                try {
                    await this.$store.dispatch('appointments/fetchAppointments', payload)
                } catch (statusCode) {
                    if (statusCode === 403) {
                        // Token has been expired or is invalid, redirect to logout
                        this.$router.push({
                            name: 'Logout'
                        })
                    }

                    if (statusCode === 500) {
                        // Do nothing
                    }
                }

                this.isLoading = false
            }
        }
    },

    watch: {
        appointments() {
            const that = this

            // we need to wait until list is rendered
            setTimeout(function() {
                that.scrollToNextAppointment()
            }, 500)
        }
    }
}
