import axios from 'axios'
import apiClient from './ApiClient'

const vinOcrKey = 'c85e58c0-a962-4bf7-aab7-c2846443c599'

const apiClientVinOCR = axios.create({
    baseURL: '',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    }
})

export default {
    scanVin(image) {
        const imageData = new FormData()
        imageData.append('file', image)

        return apiClientVinOCR.post('https://www.recognition.ws/vinocr/v1?accesscode=' + vinOcrKey, imageData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    scanLicensePlate(payload) {
        const body = {
            'image' : payload.image
        }

        return apiClient.post('/api/rpc/checkincheckout/scan-licenceplate', body, {
            headers: {
                'Authorization': `API-X-TOKEN ${payload.token}`
            }
        })
    }
}
