import Vue from 'vue'
import PinService from '../../services/PinService'

export default {
    namespaced: true,
    state: {
    },
    mutations: {
        setCorrectPin(state, payload) {
            const rootState = payload.rootState
            const correctPin = payload.correctPin
            Vue.set(rootState.form.form.summary, 'correctPin', correctPin)
        }
    },
    actions: {
        async requestPin(context, payload) {
            let response

            try {
                response = await PinService.requestPin(payload)
            } catch (e) {
                const statusCode = e.response && e.response.status

                return {
                    error: statusCode
                }
            }

            return response && response.data && response.data.pin
        },

        async validatePin(context, payload) {
            let response

            try {
                response = await PinService.validatePin(payload)
            } catch (e) {
                const statusCode = e.response && e.response.status

                return {
                    error: statusCode
                }
            }

            return response && response.data
        },

        setCorrectPin(context, correctPin) {
            const payload = {
                rootState: context.rootState,
                correctPin
            }
            context.commit('setCorrectPin', payload)
        }
    },
    getters: {
        correctPin: (state, getters, rootState) => rootState.form.form.summary.correctPin
    }
}
