import { mapGetters } from 'vuex'

export default {
    name: 'NetworkIndicator',

    mixins: [],

    data() {
        return {
            online: true
        }
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'user/loggedInUser'
        }),

        isOnline() {
            return this.online === true
        }

    },

    methods: {
        networkCheck() {
            if (navigator.onLine) {
                this.online = true
            } else {
                this.online = false
            }
        }
    },

    mounted() {
        const _me = this;
        window.addEventListener('offline', function() {
            _me.online = false
        });

        window.addEventListener('online', function() {
            _me.online = true
        });
    }
}
