import Vue from 'vue'
import Logistics from './Logistics.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import { routes } from './router/index'
import { initSentry } from './plugins/sentry'

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes
})

initSentry(Vue, router)

window.app = new Vue({
    store,
    router,
    vuetify,
    render: h => h(Logistics)
}).$mount('#app')
