import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Logout',

    props: {
        message: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {}
    },

    mounted() {
        this.logout()
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'user/loggedInUser'
        }),

        userToken() {
            return this.loggedInUser && this.loggedInUser.token
        },

        cleadupSdm() {
            localStorage.removeItem('redirectAction')
            localStorage.removeItem('redirectPlate')
            localStorage.removeItem('userMode')
        }
    },

    methods: {
        ...mapActions({
            logoutUser: 'user/logoutUser'
        }),

        async logout() {
            try {
                await this.logoutUser(this.userToken)
            } catch {
                // Do nothing
            }

            const sdm = localStorage.getItem('userMode') == 'sdm'
            if (sdm) {
                window.close()
                this.cleadupSdm()
            }

            this.$router.push({
                name: 'Login',
                params: { message: this.message }
            })
        }
    }
}
