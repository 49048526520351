import Vue from 'vue'
import moment from 'moment'
import FormService from '../../services/FormService'
import VehicleService from '../../services/VehicleService'
import { defaultForm, defaultOcr } from '../../constants/storeDefaults'
import { toBase64 } from '../../constants/file'
import { taskTypes } from '../../constants/tasks'

export default {
    namespaced: true,
    state: {
        form: JSON.parse(JSON.stringify(defaultForm))
    },
    mutations: {
        setForm(state, payload) {
            let field = state.form

            if (payload.step) {
                if (!field[payload.step]) {
                    field[payload.step] = {}
                }

                field = field[payload.step]
            }

            Vue.set(field, payload.key, payload.value)
        },

        addRunningSearch(state) {
            state.form.runningSearchCount++
        },

        removeRunningSearch(state) {
            state.form.runningSearchCount--
            if (state.form.runningSearchCount < 0) {
                state.form.runningSearchCount = 0
            }
        },

        addMissingItem(state, payload) {
            if (state.form?.handoverProtocol?.missingItems?.includes(payload.value)) {
                return
            }
            state.form.handoverProtocol.missingItems.push(payload.value)
            state.form.handoverProtocol.customerGoodIntentions[payload.value] = false
        },

        removeMissingItem(state, payload) {
            const missingPosition = state.form.handoverProtocol?.missingItems.findIndex(
                d => d === payload.value
            )
            if (missingPosition > -1) {
                state.form?.handoverProtocol?.missingItems?.splice(missingPosition, 1)
                state.form.handoverProtocol.customerGoodIntentions[payload.value] = undefined
            }
        },

        toggleSelectedListItem(state, payload) {
            state.form.handoverProtocol.customerGoodIntentions[payload.value] =
                !state.form.handoverProtocol.customerGoodIntentions[payload.value]
        },

        addDamage(state, payload) {
            state.form.damages.selectedDamages.push(payload)

            // Reset no damage flag when adding a damage
            Vue.set(state.form.damages, 'noDamages', null)
        },

        updateDamage(state, payload) {
            const damagePosition = state.form.damages.selectedDamages.findIndex(
                d => d.position.pos === payload.position.pos
            )
            if (damagePosition > -1) {
                Vue.set(
                    state.form.damages.selectedDamages,
                    damagePosition,
                    payload
                )
            }
        },

        removeDamage(state, payload) {
            const damageIndex = state.form.damages.selectedDamages.findIndex(
                d => d.position && d.position.pos === payload.position.pos
            )
            if (damageIndex > -1) {
                state.form.damages.selectedDamages.splice(damageIndex, 1)
            }
        },

        setLastFinishedStep(state, step) {
            if (state.form.lastFinishedStep < step) {
                state.form.lastFinishedStep = step
            }
        },

        setVote(state, vote) {
            Vue.set(state.form, 'vote', vote)
        },

        reset(state, payload) {
            if (payload === null) {
                payload = {}
                payload.rootState = {}
                payload.rootState.ocr = {}
            }
            const rootState = payload.rootState
            state.form = JSON.parse(JSON.stringify(defaultForm))
            rootState.ocr.ocr = JSON.parse(JSON.stringify(defaultOcr))
        },

        resetExceptUploads(state) {
            // Collect customer data
            const identityCard = state.form.identityCard
            const drivingLicense = state.form.drivingLicense

            // Reset form
            state.form = JSON.parse(JSON.stringify(defaultForm))

            // Apply customer data
            Vue.set(state.form, 'identityCard', identityCard)
            Vue.set(state.form, 'drivingLicense', drivingLicense)
            Vue.set(state.form, 'finishedPreviousTaskForCustomer', true)
        },

        log(state, payload) {
            const now = moment()
            const log = {
                step: payload.step,
                time: now.format('YYYY-MM-DD HH:mm:ss'),
                details: payload.details || {}
            }

            state.form.log.push(log)
        },

        setTask(state, task) {
            Vue.set(state.form, 'task', task)
        }
    },
    actions: {
        setForm(context, payload) {
            context.commit('setForm', payload)
        },

        addSelectListItem(context, payload) {
            if (payload.key == 'missingItems') {
                context.commit('addMissingItem', payload)
            }
        },

        removeSelectListItem(context, payload) {
            if (payload.key == 'missingItems') {
                context.commit('removeMissingItem', payload)
            }
        },

        toggleSelectedListItem(context, payload) {
            context.commit('toggleSelectedListItem', payload)
        },

        addDamage(context, payload) {
            context.commit('addDamage', payload)
        },

        updateDamage(context, payload) {
            context.commit('updateDamage', payload)
        },

        removeDamage(context, payload) {
            context.commit('removeDamage', payload)
        },

        setLastFinishedStep(context, step) {
            context.commit('setLastFinishedStep', step)
        },

        async fetchVehicle(context, payload) {
            const params = payload.params
            const token = payload.token
            let response
            let vehicle
            let key = 'vehicleByVin'
            context.commit('addRunningSearch')

            if (params.license_plate) {
                key = 'vehicleByLicensePlate'
            }

            try {
                if (params.type === taskTypes.MISSING_PARTS_CHECKIN) {
                    response = await VehicleService.fetchProcessedVehicle(params, token)
                } else {
                    response = await VehicleService.fetchVehicle(params, token)
                }
                vehicle = response.data
            } catch (e) {
                const statusCode = e.response?.status

                // Reset found vehicle
                context.commit('setForm', {
                    key,
                    value: {},
                    step: null
                })

                context.commit('removeRunningSearch')

                return {
                    error: statusCode
                }
            } finally {
                context.commit('removeRunningSearch')
            }

            if (!vehicle) {
                return {
                    error: 404
                }
            }

            // Reset found vehicle
            context.commit('setForm', {
                key,
                value: {},
                step: null
            })

            let vehicleStructured = {}
            let vehicleSrc = vehicle
            if (params.type === taskTypes.MISSING_PARTS_CHECKIN) {
                vehicleSrc = vehicle.checkinCheckout
            }

            if (Object.keys(vehicleSrc).length > 0 && vehicleSrc.id) {
                // Use different structure than API response
                vehicleStructured = {
                    id: vehicleSrc.id,
                    vin: vehicleSrc.carVin,
                    manufacturer: vehicleSrc.carManufacturer,
                    model: vehicleSrc.carModel,
                    color: vehicleSrc.carColor,
                    license_plate: vehicleSrc.carRegistrationPlate,
                    contractStateIsValid: vehicleSrc.contractStateIsValid,
                    customer: {
                        first_name: vehicleSrc.customerFirstname,
                        last_name: vehicleSrc.customerLastname,
                        address_street: vehicleSrc.customerStreet,
                        address_zip: vehicleSrc.customerZipcode,
                        address_city: vehicleSrc.customerCity,
                        identity_card_number: vehicleSrc.customerIdcode,
                        driver_license_number: vehicle.customerLicenceIdcode,
                        phone_number: vehicleSrc.customerPhone,
                        useEasycheck: vehicleSrc.customerUseEasycheck
                    },
                    contractSepaNotRequired: vehicleSrc.contractSepaNotRequired,
                    authorizedRepresentative: {
                        first_name: vehicleSrc.authorizedFirstname,
                        last_name: vehicleSrc.authorizedLastname,
                        address_street: vehicleSrc.authorizedStreet,
                        address_zip: vehicleSrc.authorizedZipcode,
                        address_city: vehicleSrc.authorizedCity,
                        identity_card_number: vehicleSrc.authorizedIdcode,
                        driver_license_number: vehicleSrc.authorizedLicenceIdcode,
                        phone_number: vehicleSrc.authorizedPhone,
                        useEasycheck: vehicleSrc.customerUseEasycheck
                    },
                    hasService: vehicleSrc.hasService,
                    isDeregistrationPending: vehicleSrc.carIsDeregistrationPending,
                    currentEquipment: JSON.parse(vehicleSrc.currentEquipment || '[]') || [],
                    currentEquipmentItemsMissing: JSON.parse(vehicleSrc.currentEquipmentItemsMissing || '[]') || [],
                    currentAdditionalItems: vehicleSrc.currentAdditionalItems,
                    currentAdditionalItemsMissing: vehicleSrc.currentAdditionalItemsMissing
                }
                if (params.type === taskTypes.MISSING_PARTS_CHECKIN) {
                    vehicleStructured.processed = vehicle
                    vehicleStructured.orgMissingItems = Object.assign([], vehicle.missingItems)
                }

                context.commit('setForm', {
                    key,
                    value: vehicleStructured,
                    step: null
                })

                return vehicleStructured
            } else {
                return {
                    error: 404
                }
            }
        },

        setVehicle(context, vehicle) {
            // Reset vehicle and customer
            context.commit('setForm', {
                key: 'vehicle',
                value: {},
                step: null
            })

            if (vehicle) {
                context.commit('setForm', {
                    key: 'vehicle',
                    value: vehicle,
                    step: null
                })
            }
        },

        reset(context) {
            const payload = {
                rootState: context.rootState
            }
            context.commit('reset', payload)
        },

        resetExceptUploads(context) {
            context.commit('resetExceptUploads')
        },

        async sendForm({ commit, state }, payload) {
            let response, damagePhotoAsBase64, damage, damagePhoto

            // Add process_id to form (needed by endpoint)
            Vue.set(state.form, 'process_id', (state.form.vehicle?.id) || null)

            // Add reason when form was canceled (needed by endpoint)
            if (payload.canceled) {
                Vue.set(state.form, 'reason', (state.form.cancel?.cancelReason) || 'Benutzerabbruch')
            }

            // Convert images to base64 for sending as JSON
            if (
                state.form?.damages?.selectedDamages?.length > 0
            ) {
                for (const damageIndex of state.form.damages.selectedDamages.keys()) {
                    damage = state.form.damages.selectedDamages[damageIndex]
                    if (damage?.photos?.length > 0) {
                        for (const damagePhotoIndex of damage.photos.keys()) {
                            damagePhoto = damage.photos[damagePhotoIndex]
                            damagePhotoAsBase64 = await toBase64(damagePhoto)

                            // Setting base64 of file
                            Vue.set(damage.photosAsBase64, damagePhotoIndex, damagePhotoAsBase64)
                        }
                    }
                }
            }

            const sendPayload = {
                form: state.form,
                token: payload.token
            }

            try {
                if (payload.type == 'checkincheckout') {
                    if (!payload.canceled) {
                        response = await FormService.send(sendPayload)
                    } else {
                        response = await FormService.cancel(sendPayload)
                    }
                } else {
                    response = await FormService.returnMissingItems(sendPayload)
                }
            } catch (e) {
                const statusCode = e.response?.status

                commit('log', {
                    step: 'sendForm',
                    details: { type: 'error', message: e.message }
                })

                return {
                    error: statusCode
                }
            }

            return response
        },

        log(context, payload) {
            context.commit('log', payload)
        },

        setTask(context, task) {
            context.commit('setTask', task)
        }
    },
    getters: {
        form: state => state.form
    }
}
