import { mapGetters } from 'vuex'
import { getTaskConfigByName } from '../../constants/tasks'
import { getStepNumber } from '../../utils/index'
import checkTaskMixin from '../../mixins/checkTaskMixin'

import NetworkIndicator from '../networkIndicator/NetworkIndicator.vue'

export default {
    name: 'PageHeader',

    mixins: [checkTaskMixin],

    components: {
        NetworkIndicator
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters({
            form: 'form/form',
            loggedInUser: 'user/loggedInUser'
        }),

        activeStep() {
            return (
                Number(
                    this.$route && this.$route.params && this.$route.params.step
                ) || 1
            )
        },

        loggedInUserCompany() {
            return (this.loggedInUser && this.loggedInUser.location) || ''
        },

        componentName() {
            return this.$route && this.$route.name
        },

        numberOfSteps() {
            let numberOfSteps = null

            const taskName = this.componentName
            const taskConfig = getTaskConfigByName(taskName)
            if (taskConfig) {
                numberOfSteps = Object.keys(taskConfig.steps).length
            }

            return numberOfSteps
        },

        lastFinishedStep() {
            return this.form.lastFinishedStep
        }
    },

    methods: {
        navigateTo(step) {
            // Disable click for forbidden step
            const forbiddenStepNumber = getStepNumber(
                'IdentityCard',
                'CustomerCheckin'
            )
            if (
                this.componentName === 'CustomerCheckin' &&
                this.form.authority.alternativePerson &&
                forbiddenStepNumber === step
            ) {
                return
            }

            if (
                this.lastFinishedStep >= step &&
                this.activeStep !== step &&
                !this.summaryFinishedByCustomer
            ) {
                this.$router.push({
                    name: this.componentName,
                    params: { step }
                })
            }
        },

        logout() {
            this.$router.push({
                name: 'Logout'
            })
        }
    }
}
