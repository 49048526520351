import { mapGetters } from 'vuex'
import { lowerCaseFirst } from '../utils/index'
import EventBus from '../utils/eventBus'

export default {
    props: {
        model: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        sublabel: {
            type: String
        },
        values: {
            type: Array
        },
        items: {
            type: Array
        },
        error: {
            type: Boolean,
            required: false
        },
        type: {
            type: String,
            default: 'text'
        },
        reference: {
            type: String,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        autocomplete: {
            type: String,
            default: 'off'
        },
        step: {
            type: String,
            default: null
        },
        saveToStore: {
            type: Boolean,
            default: true
        },
        selected: {},
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        compModel: {
            get() {
                let compModel = this.field && this.field[this.model]

                // Use selected and not store value when not saving to store directly
                // (needed for editing mode of damages for example)
                if (!this.saveToStore) {
                    compModel = this.selected
                }

                return compModel
            },
            set(value) {
                if (this.saveToStore) {
                    EventBus.$emit('setForm', {
                        key: this.model,
                        value,
                        step: this.step
                    })
                }

                this.$emit('update:selected', value)
            }
        },

        componentName() {
            return lowerCaseFirst(this.$options.name)
        },

        field() {
            return this.form[this.step] || {}
        }
    },

    methods: {
        validate() {
            EventBus.$emit('removeError', this.model)
        }
    }
}
