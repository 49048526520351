import moment from 'moment'
import {
    getAppointmentTypeNameById,
    getAppointmentCustomFieldKey
} from '../../../../constants/appointments'

export default {
    name: 'Appointment',

    props: {
        appointment: {
            type: Object,
            required: true
        }
    },

    computed: {
        timeNow() {
            return moment(new Date(), 'HH:mm')
        },

        time() {
            return moment(this.appointment.startDate).format('HH:mm')
        },

        timesUp() {
            const time = moment(this.appointment.startDate).add(30, 'minutes')
            return time.isBefore(this.timeNow)
        },

        customer() {
            return (
                (this.appointment.singleCustomers &&
                    this.appointment.singleCustomers[0] &&
                    this.appointment.singleCustomers[0]) ||
                {}
            )
        },

        firstName() {
            return this.customer.firstName
                ? this.customer.firstName[0] + '.'
                : ''
        },

        lastName() {
            return this.customer.lastName
        },

        type() {
            return getAppointmentTypeNameById(
                this.appointment && this.appointment.serviceId
            )
        },

        licensePlate() {
            const key = getAppointmentCustomFieldKey('licensePlate')
            let licensePlate = '–'
            if (key) {
                licensePlate =
                    (this.appointment &&
                        this.appointment.customfields &&
                        this.appointment.customfields[key] &&
                        this.appointment.customfields[key].value) ||
                    '–'
            }

            return licensePlate
        },

        vin() {
            const key = getAppointmentCustomFieldKey('vin')
            let vin = '–'
            if (key) {
                vin =
                    (this.appointment &&
                        this.appointment.customfields &&
                        this.appointment.customfields[key] &&
                        this.appointment.customfields[key].value) ||
                    '–'
            }

            return vin
        }
    }
}
