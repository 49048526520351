import apiClient from './ApiClient'

export default {
    fetchToken(payload) {
        return apiClient.post('/api/rpc/authentication_token', payload)
    },

    fetchRefreshedToken(token) {
        return apiClient.get('/api/user/refresh_token', {
            headers: {
                Authorization: 'API-X-TOKEN ' + token
            }
        })
    },

    fetchUserForToken(token) {
        return apiClient.get('/api/user/data', {
            headers: {
                Authorization: 'API-X-TOKEN ' + token
            }
        })
    },

    logout(token) {
        return apiClient.get('/api/user/logout', {
            headers: {
                Authorization: 'API-X-TOKEN ' + token
            }
        })
    }
}
