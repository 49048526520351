const appointmentTypes = [
    {
        name: 'Rückgabe',
        id: '5e58d1205839ad1203d90ae9'
    },
    {
        name: 'Abholung',
        id: '5e58d0fb9cee0211f31faaf0'
    },
    {
        name: 'Tausch',
        id: '5e58d10da5042312083ee87a'
    }
]

const getAppointmentTypeNameById = id => {
    let name = '–'

    const position = appointmentTypes.find(aT => aT.id === id)

    if (position) {
        name = position.name
    }

    return name
}

const appointmentCustomFields = [
    {
        name: 'licensePlate',
        key: 1
    },
    {
        name: 'vin',
        key: 2
    }
]

const getAppointmentCustomFieldKey = fieldName => {
    let key = null

    const position = appointmentCustomFields.find(cF => cF.name === fieldName)
    if (position) {
        key = position.key
    }

    return key
}

export { getAppointmentTypeNameById, getAppointmentCustomFieldKey }
