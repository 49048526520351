import { mapGetters } from 'vuex'

export default {
    data() {
        return {}
    },

    mounted() {
        if (this.loggedInUser && !this.loggedInUser.token) {
            this.$router.push({ name: 'Login' }).catch()
        }
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'user/loggedInUser'
        })
    },

    methods: {}
}
