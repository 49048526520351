import EventBus from './utils/eventBus'
import { mapActions, mapGetters } from 'vuex'
import PageHeader from './components/pageHeader/PageHeader.vue'
import CancelDialog from './components/dialog/cancel/Cancel.vue'
import ConfirmCancelDialog from './components/dialog/cancel/ConfirmCancel.vue'
import VoteDialog from './components/dialog/vote/Vote.vue'
import SuccessDialog from './components/dialog/success/Success.vue'
import PhotoDialog from './components/dialog/photo/Photo.vue'
import ErrorDialog from './components/dialog/error/Error.vue'
import Spinner from './components/spinner/Spinner.vue'
import checkTaskMixin from './mixins/checkTaskMixin'

export default {
    name: 'Logistics',

    mixins: [checkTaskMixin],

    components: {
        PageHeader,
        CancelDialog,
        ConfirmCancelDialog,
        VoteDialog,
        SuccessDialog,
        PhotoDialog,
        ErrorDialog,
        Spinner
    },

    data() {
        return {
            missingItemsReturnedConfirmed: false,
            showDialog: {},
            task: null
        }
    },

    created() {
        this.initListener()
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'user/loggedInUser',
            form: 'form/form'
        }),

        showHeader() {
            return this.$route && this.$route.name !== 'Login'
        }
    },

    methods: {
        ...mapActions({
            sessionRefresh: 'user/sessionRefresh',
            setForm: 'form/setForm',
            addSelectListItem: 'form/addSelectListItem',
            removeSelectListItem: 'form/removeSelectListItem',
            toggleSelectedListItem: 'form/toggleSelectedListItem',
            addDamage: 'form/addDamage',
            updateDamage: 'form/updateDamage',
            removeDamage: 'form/removeDamage'
        }),

        initListener() {
            EventBus.$on('setForm', payload => {
                this.setForm(payload)
            })
            EventBus.$on('addSelectListItem', payload => {
                this.addSelectListItem(payload)
            })
            EventBus.$on('removeSelectListItem', payload => {
                this.removeSelectListItem(payload)
            })
            EventBus.$on('toggleSelectedListItem', payload => {
                this.toggleSelectedListItem(payload)
            })
            EventBus.$on('addDamage', payload => {
                this.addDamage(payload)
            })
            EventBus.$on('updateDamage', payload => {
                this.updateDamage(payload)
            })
            EventBus.$on('removeDamage', payload => {
                this.removeDamage(payload)
            })
            EventBus.$on('showDialog', payload => {
                this.showDialog = payload.dialog
                this.task = payload.task
            })
            EventBus.$on('hideDialog', () => {
                this.showDialog = {}
            })
            EventBus.$on('removeError', () => {
                if (this.showDialog && this.showDialog.name === 'error') {
                    this.showDialog = {}
                }
            })
            EventBus.$on('send', payload => {
                this.task = payload.task
                this.sendForm()
            })

            EventBus.$on('cancel', payload => {
                this.task = payload.task
                this.sendForm(true)
            })

            setInterval(async () => {
                try {
                    const refreshed = await this.sessionRefresh();
                    if (refreshed) {
                        console.log('Refreshed session')
                    }
                } catch (e) {
                    console.log('Session refresh error', e)
                }
            }, 15000)
        },

        async sendForm(canceled = false) {
            const payload = {
                token: this.loggedInUser?.token,
                type: 'checkincheckout',
                canceled
            }

            if (this.isRecordMissingPartsTask) {
                if (!this.form?.summary?.confirmRetunredMissingItems || canceled) {
                    return
                }
                payload.type = 'returnedmissingitems'
            }

            if(!canceled){
                EventBus.$emit('spinner', { show: true })
            }

            const response = await this.$store.dispatch(
                'form/sendForm',
                payload
            )

            // Handling errors
            if (response?.error) {
                const statusCode = response.error
                EventBus.$emit('spinner', { show: false })

                if (statusCode === 403) {
                    // Token has been expired or is invalid, redirect to logout
                    this.$router.push({
                        name: 'Logout'
                    }).catch()

                    return
                }

                else if(![200].includes(statusCode)) {
                    // Error from API
                    EventBus.$emit('showDialog', {
                        dialog: {
                            name: 'error',
                            type: 'hint',
                            showContact: true,
                            errors: [
                                `Es gab ein Problem bei der Kommunikation mit der Schnittstelle (Error ${statusCode}).`
                            ]
                        }
                    })

                    return
                }
            }

            if (response && !response.error && !payload.canceled) {
                EventBus.$emit('spinner', { show: false })
                this.showDialog = {
                    name: 'success'
                }
            }
        }
    }
}
