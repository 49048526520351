import moment from 'moment'
import apiClient from './ApiClient'

export default {
    fetchAppointments(payload) {
        return apiClient.get(
            '/api/rpc/timify/' + 'appointments',
            {
                params: {
                    from_date: moment().format('YYYY-MM-DD'),
                    to_date: moment().format('YYYY-MM-DD'),
                    refresh: payload.refresh
                },

                headers: {
                    Authorization: 'API-X-TOKEN ' + payload.token
                }
            }
        )
    }
}
