import Vue from 'vue'
import Vuex from 'vuex'

import form from './modules/form'
import user from './modules/user'
import appointments from './modules/appointments'
import ocr from './modules/ocr'
import pin from './modules/pin'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        form,
        user,
        appointments,
        ocr,
        pin
    }
})
