import Compressor from 'compressorjs'

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})

const resizePhoto = photo => {
    return new Promise(resolve => {
        if (photo) {
            return new Compressor(photo, {
                quality: 0.6,
                maxWidth: 1000,
                success(resizedPhoto) {
                    resolve(resizedPhoto)
                },
                error(err) {
                    console.log(err.message)
                    resolve(photo)
                }
            })
        }
    })
}

export { toBase64, resizePhoto }
