import { render, staticRenderFns } from "./SdmError.vue?vue&type=template&id=13cfcdb8&scoped=true&"
import script from "./sdmError.js?vue&type=script&lang=js&"
export * from "./sdmError.js?vue&type=script&lang=js&"
import style0 from "./sdmError.scss?vue&type=style&index=0&id=13cfcdb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13cfcdb8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
