import apiClient from './ApiClient'

export default {
    fetchVehicle(params, token) {
        return apiClient.get('/api/rpc/checkincheckout/vehicles', {
            params,
            headers: {
                Authorization: 'API-X-TOKEN ' + token
            }
        })
    },

    fetchProcessedVehicle(params, token) {
        return apiClient.get('/api/rpc/checkincheckout/processedcheckin', {
            params,
            headers: {
                Authorization: 'API-X-TOKEN ' + token
            }
        })
    }
}
