import EventBus from '../../utils/eventBus'

export default {
    name: 'Spinner',

    data: () => ({
        overlay: false
    }),

    created() {
        this.initEvents()
    },

    methods: {
        initEvents() {
            EventBus.$on('spinner', ({ show }) => {
                this.overlay = show
            })
        }
    }
}
