import ErrorOutput from './output/Output.vue'

export default {
    name: 'Error',

    props: {
        errors: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            default: 'hint'
        },
        showContact: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ErrorOutput
    },

    data() {
        return {
            showDialog: true
        }
    }
}
